import { useContext, useEffect } from 'react'

import { TUserType } from '@unionco/alaris-app-types'

import { isAdminTracking } from 'utils/tracking'
import { pageLoadTracking } from 'utils/tracking/pageLoad'

import { PreAuthGlobalsContext } from 'context'

type TUsePageLoadTracking = (
  pageName: string,
  userType?: TUserType,
  callback?: () => void
) => void

/**
 * Basic Page Load Tracking Hook
 *
 * For pages that only require basic tracking,
 * aka, don't need anything beyond the page name
 * and other default tracking information.
 * Just needs the page name passed in
 *
 * @param pageName: string of page name.
 * @param userType: @see TUserType optional param required for pages that need auth
 *
 * @returns void: This is used to send off page load tracking info to google analytics
 */
export const usePageLoadTracking: TUsePageLoadTracking = (
  pageName,
  userType,
  callback = () => {
    return undefined
  }
) => {
  const { pageLoaded, setPageLoadedTrue } = useContext(PreAuthGlobalsContext)
  useEffect(() => {
    if (pageLoaded) return
    pageLoadTracking({
      page: pageName,
      is_admin_key: userType ? isAdminTracking(userType) : undefined
    })
    callback()
    setPageLoadedTrue()
  }, [pageLoaded, setPageLoadedTrue])
}
