/** Login Page - Query File
 *  For markup see /templates/modal/login
 */
import { signIn } from 'next-auth/react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

import { DashboardPageSlug } from 'appConstants'

import { UserContext } from 'context'

import { usePageLoadTracking } from 'hooks/usePageLoadTracking'

import { AuthenticationTemplate } from 'templates'

import { LoginCard } from 'components'

export const LoginPage: React.FC = () => {
  const router = useRouter()
  const { query } = router
  const userData = useContext(UserContext)
  usePageLoadTracking('login')

  /** Email Login Redirect Effect
   *  Takes the query parameter loginToken
   *  the on a successful signIn moves user to
   *  the dashboard
   */
  useEffect(() => {
    if (!query || !query.loginToken) return
    signIn('credentials', {
      redirect: false,
      token: query.loginToken
    }).then((res) => {
      const needsToAgree = userData ? userData.showAgreement : true
      if (!!res && res.status === 200 && !needsToAgree) {
        router.replace(DashboardPageSlug)
      }
    })
  }, [router, query, userData])

  return (
    <>
      <Head>
        <title>Alaris Login</title>
        <meta property='og:title' content='Alaris Login' key='title' />
      </Head>
      <AuthenticationTemplate>
        <LoginCard />
      </AuthenticationTemplate>
    </>
  )
}

export default LoginPage
